const short = require('short-uuid');

export const generateRefCodeByShortUUID = () => {
  return short.generate(); // '73WakrfVbNJBaAmhQtEeDv'
};

export const formatPrice = (price) => {
  return Number(price).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
};

export const isGeneratedClientSessionId = () => {
  const operationInfos = JSON.parse(localStorage.getItem('operationInfos'));
  if (operationInfos && operationInfos?.clientInfos) {
    return operationInfos?.clientInfos;
  }
  return false
}

// Function to remove the client_id_selecting property
export const removePropertyHelper = (array, propertyToRemove) => {
  return array.map(obj => {
    const { [propertyToRemove]: removed, ...rest } = obj;
    return rest;
  });
}

//  calcul functions
export const countTotalPrice = (cartProducts) => {
  return cartProducts.reduce((total, prod) => total + (prod.price * prod.quantity), 0);
}

export const countTotalSupplementsOfAllProducts = (cartProducts) => {
  let supplementsTotal = 0;
  cartProducts.forEach((prod) => {
    if (prod.supplements) {
      supplementsTotal += prod?.supplements.reduce((total, supp) => total + supp.price_supplement, 0)
    }
  })
  return supplementsTotal;
}

export const countTotalSuggetsionsOfAllProducts = (cartProducts) => {
  let suggestionsTotal = 0;
  cartProducts.forEach((prod) => {
    if (prod.suggestions) {
      suggestionsTotal += prod?.suggestions.reduce((total, sugg) => total + sugg.productPrice, 0)
    }
  })
  return suggestionsTotal;
}

export const countTotalPacksOfAllProducts = (cartProducts) => {
  let packsTotal = 0;
  cartProducts.forEach((prod) => {
    if (prod.packs) {
      packsTotal += prod?.packs.reduce((total, prodPack) => total + prodPack.productPrice, 0)
    }
  })
  return packsTotal;
}

export const countAllTotals = (productsData) => {

  return countTotalPrice(productsData)
    + countTotalSupplementsOfAllProducts(productsData)
    + countTotalSuggetsionsOfAllProducts(productsData)
    + countTotalPacksOfAllProducts(productsData)
}

// calcul singleProduct items
export const countTotalSupplementsOfSingleProducts = (prod) => {
  let supplementsTotal = 0;
  if (prod?.supplements) {
    supplementsTotal = prod?.supplements.reduce((total, supp) => total + supp.price_supplement, 0)
    return supplementsTotal;
  } else {
    return supplementsTotal;
  }

}

export const countTotalSuggetsionsOfSingleProducts = (prod) => {
  let suggestionsTotal = 0;
  if (prod?.suggestions) {
    suggestionsTotal += prod?.suggestions.reduce((total, sugg) => total + sugg.productPrice, 0)
    return suggestionsTotal;
  }
  return suggestionsTotal;
}

export const countTotalPacksOfSingleProducts = (prod) => {
  let packsTotal = 0;
  if (prod?.packs) {
    packsTotal += prod?.packs.reduce((total, pack) => total + pack.productPrice, 0)
    return packsTotal;
  }
  return packsTotal;
}

export const showTotalPlusProductSuppsAndSuggsAndPacks = (prod) => {
  return formatPrice(countTotalSupplementsOfSingleProducts(prod)
    + countTotalSuggetsionsOfSingleProducts(prod)
    + countTotalPacksOfSingleProducts(prod))
}

export const countAllTotalsOfSingleProduct = (prod, quantity) => {
  return (prod?.price * quantity)
    + countTotalSupplementsOfSingleProducts(prod)
    + countTotalSuggetsionsOfSingleProducts(prod)
    + countTotalPacksOfSingleProducts(prod)
}


export const removeDuplicatedObjectFromArray = (arr) => {
  const uniqueClient_id = [];
  const uniqueItems = [];

  for (let i = 0; i < arr.length; i++) {
    if (!uniqueClient_id.includes(arr[i].client_id)) {
      uniqueClient_id.push(arr[i].client_id);
      uniqueItems.push(arr[i]);
    }
  }

  return uniqueItems;
}

export const getCurrentDateTimeVisibility = () => {
  const date = new Date();

  // get Day (convert 0 for Sunday to 7)
  const day = date.getDay() === 0 ? 7 : date.getDay();

  // get Time
  const hour = String(date.getHours()).padStart(2, '0'); // Ensure 12-hour format
  const minute = String(date.getMinutes()).padStart(2, '0');
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

  return {
    day,
    time: `${hour}:${minute}:00`
  }
}

export const generateUniqueOrderNumber = () => {
  const now = new Date();

  // Extract year, month, day, hour, and minute
  const year = now.getFullYear().toString().slice(-2); // '24' for 2024
  const month = String(now.getMonth() + 1).padStart(2, '0'); // '01' to '12'
  const day = String(now.getDate()).padStart(2, '0'); // '01' to '31'
  const hour = String(now.getHours()).padStart(2, '0'); // '00' to '23'
  const minute = String(now.getMinutes()).padStart(2, '0'); // '00' to '59'

  // Create the random part of the order number
  const randomPart = Math.floor(Math.random() * 10000).toString().padStart(4, '0'); // '0000' to '9999'

  // Combine into the desired format
  return `ORDER_N${year}${month}${day}${hour}${minute}C${randomPart}`;
}


export const getAllProductsFromAllOrdersByTable = (ordersByTable) => {
  let allOrdersProductsByTable = []

  ordersByTable.forEach(order => {
    allOrdersProductsByTable = [...allOrdersProductsByTable, ...order.products]
  })

  return allOrdersProductsByTable;
}
export const currentDateReceiptFormatter = () => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = now.getFullYear();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export const generateReceiptId = () => {
  const uniqueNumber = Math.floor(Math.random() * 10000000); // Generates a random number with up to 7 digits
  return `receipt_${uniqueNumber}`;
}

// Fonction pour générer une chaîne aléatoire (rnd) de 20 caractères
export const generatePaymentRnd = () => {
  return Math.random().toString(36).substring(2, 22);
}
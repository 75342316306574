import React, { useState, useEffect } from 'react';
import { Wheel } from 'react-custom-roulette';
import { createCouponService, getMiamusDiscountsWithSettings } from '../common/apiServices/coupon/coupon.service';

export default function WheelModal({ showModalWheel, setShowModalWheel, email }) {
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    const [selectedOption, setSelectedOption] = useState(''); // New state to hold the selected option
    const [messageCouponByDiscount, setMessageCouponByDiscount] = useState('');

    const discountBackgroundColors = [
        '#F44336',
        '#FF9800',
        '#FFEB3B',
        '#8BC34A',
        '#03A9F4',
        '#9C27B0',
        '#FF5722'
    ]
    const [discountsData, setDiscountsData] = useState([])
    const handleSpinClick = () => {
        if (discountsData.length > 0) {
            if (!mustSpin) {
                const newPrizeNumber = Math.floor(Math.random() * discountsData.length);
                setPrizeNumber(newPrizeNumber);
                setMustSpin(true);
            }
        }
    }

    useEffect(() => {
        // get discount miamus settings & open wheel when success
        getMiamusDiscountsWithSettings()
            .then((data) => {
                if (data && data.discountsList) {
                    // Filter only where number of tickets is not 0
                    const filtredDiscounts = data.discountsList.filter((item) => item.number_tickets !== 0);
                    const discountsToShow = filtredDiscounts.map((item, index) => ({
                        option: `${item?.discount}%`,
                        style: {
                            backgroundColor: discountBackgroundColors[index % discountBackgroundColors.length],
                            fontSize: 18,
                            fontFamily: 'Arial',
                            fontWeight: 500,
                            textColor: 'white'
                        },
                        // Ensure looping colors if data is longer
                    }));

                    if (discountsToShow.length > 0) {
                        setDiscountsData(discountsToShow); // Trigger another useEffect when discountsData updates
                    }
                }
            })
            .catch((err) => console.log(err));
    }, [showModalWheel]);

    // Trigger spinning when discountsData is updated
    useEffect(() => {
        if (discountsData.length > 0) {
            handleSpinClick(); // Initiate the spin
        }
    }, [discountsData]);


    const confirmDiscount = (discount) => {
        // call api to set gotten discount & close
        const bodyObj = {
            email,
            discount: discount.split("%")[0]
        }
        createCouponService(bodyObj)
            .then((data) => {
                if (data && data.code === 1) {
                    setMessageCouponByDiscount('Votre coupon est envoyé par Email !')
                    setShowModalWheel(false)
                } else {
                    console.log("data :", data)
                }
            })
            .catch(err => console.log(err))
    }
    return (
        <div className="modal fade show" id="wheelModal" tabIndex="-1" aria-labelledby="wheelModalLabel" aria-hidden="true"
            style={{
                display: 'block',
                position: "absolute",
                display: 'flex',
                alignItems: 'center',
                justifyCcontent: 'center',
            }}
        >
            <div className="modal-dialog" style={{
                margin: 'auto',
                width: 'fit-content',
                maxWidth: '90vw',
                // height: "500px",
                maxHeight: "80vh",
                borderRadius: '10px',
            }}>
                <div className="modal-content"
                    style={{ backgroundColor: selectedOption ? 'white' : 'transparent' }}
                >
                    <div className="modal-body">
                        <div className="">
                            {
                                discountsData.length > 0 && (
                                    <Wheel
                                        mustStartSpinning={mustSpin}
                                        prizeNumber={prizeNumber}
                                        data={
                                            discountsData
                                        }
                                        onStopSpinning={() => {
                                            setMustSpin(false);
                                            setSelectedOption(discountsData[prizeNumber].option); // Set the selected option
                                        }}
                                        outerBorderWidth={1.5}  // Reduce thickness of outer border
                                        radiusLineWidth={1.5}  // Reduce thickness of option separators
                                    />
                                )
                            }
                            {selectedOption && (
                                <div
                                    className="text-center "
                                    style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <p>Votre chance : {selectedOption}</p>
                                    <button className='btn btn-warning' style={{ fontSize: 12 }} onClick={() => confirmDiscount(selectedOption)}>Confirmer</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
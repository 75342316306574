
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ReviewStarsModal from './ReviewStarsModal';
import { sendEmailMiamusService } from '../common/apiServices/coupon/coupon.service';
import WheelModal from './WheelModal';

export default function ReviewStars() {
    const receiptInfosSelector = useSelector((state) => state?.operation?.operationInfos?.receiptInfos)
    const navigate = useNavigate()
    const { isreview, isopen } = useParams()
    const [emailMiamus, setEmailMiamus] = useState("");
    const [messageSuccessSendEmail, setMessageSucessSendEmail] = useState("");

    // coupon process
    const [showModalReviewStars, setShowModalReviewStars] = useState((isreview === 'yes' && isopen === 'yes') ? true : false);
    const [showModalWheel, setShowModalWheel] = useState(false)

    const handleChangeEmailMiamus = (event) => {
        const miamusEmail = event.target.value;
        setEmailMiamus(miamusEmail)
    }

    const handleSubmitEmailMiamus = (event) => {
        event.preventDefault();
        if (emailMiamus !== "") {
            // send Eamil to API
            sendEmailMiamusService({ email: emailMiamus })
                .then((data) => {
                    // check if email already exist to allow Roulette
                    const { isEmailExist } = data;
                    if (isEmailExist === 1) {
                        // allow Roulette
                        setShowModalWheel(true)
                    } else {
                        setEmailMiamus('');
                        setMessageSucessSendEmail("Votre email a été envoyé !")
                    }
                })
                .catch((err) => console.log(err))
        }
    }

    return (
        <div style={{
            backgroundColor: '#f7f6fa'
        }}>
            <div className="section_1 m-2 bg-white px-1 py-3">
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "14px",
                            margin: 0
                        }}
                    >
                        <p style={{
                            fontSize: "15px",
                            fontFamily: "Poppins",
                            margin: 0,
                            color: '#FF7900'
                        }}
                        >
                            <b>Vous avez payé </b>
                        </p>
                        <p style={{
                            fontSize: "15px",
                            fontFamily: "Poppins",
                            margin: 0
                        }}
                        >
                            <b>{`${receiptInfosSelector && receiptInfosSelector?.paidAmount} Dhs`}</b>
                        </p>
                    </li>
                </ul>
                <div style={{ marginTop: 20, paddingLeft: 15, paddingRight: 15 }}>
                    <p style={{ fontSize: 14, fontWeight: 500, fontFamily: 'Poppins', color: '#1B0B29' }}>
                        L’équipe du restaurant est informée de votre paiement
                        et vous remercie pour votre visite.<br /> À très bientôt !
                    </p>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <button
                        className="btn btn-lg mt-1"
                        type="button"
                        style={{
                            borderColor: "white",
                            background: "linear-gradient(to right, #FCC546, #FDD681)",
                            color: '#1E1E1E',
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: "600",
                            borderRadius: '20px',
                            paddingLeft: '20px',
                            paddingRight: '33px'
                        }}
                        onClick={() => window.open(`/receipt`, '_blank')}
                    >
                        <img src='/images/reviews/receipt_icon.svg' style={{ marginRight: 8 }} />
                        Obtenir mon reçu
                    </button>
                </div>
            </div>
            <div className="section_2 m-3 bg-white p-3 ">
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <h4 style={{
                        fontSize: 17,
                        fontWeight: 600,
                        fontFamily: 'Poppins'
                    }}>En payant via Tablati, Vous avez gagné  <span style={{ color: '#FCC546' }}>2 000 Miamus </span>! BRAVO!</h4>
                    <img src='/images/reviews/coin.png' />
                </div>
                <div className="my-2">
                    <p style={{
                        fontSize: 13,
                        fontWeight: 500,
                        color: '#8B8B8B',
                        fontFamily: 'Poppins',
                        fontStyle: 'italic'
                    }}>
                        Vous pouvez utilisez vos Miamus chez tous nos
                        restaurant partenaire.
                    </p>
                </div>
                <div>
                    <form onSubmit={handleSubmitEmailMiamus}>
                        <label for="exampleFormControlInput1" class="form-label" style={{
                            fontSize: 11.5,
                            fontWeight: 500,
                            color: '#1B0B29',
                            fontFamily: 'Poppins',
                        }}>Entrez votre e-mail et savourez la récompense !</label>
                        <div class="mb-2"
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <input
                                type="email"
                                class="form-control"
                                id="email_miamus"
                                onChange={handleChangeEmailMiamus}
                                value={emailMiamus}
                                required
                                style={{
                                    width: '400px',
                                }}
                            />
                            <button className="btn btn-dark" type="submit">
                                Envoyer
                            </button>
                        </div>
                        <div
                            onClick={() => setMessageSucessSendEmail("")}
                            style={{ cursor: 'pointer', padding: 0, margin: 0 }}
                        >
                            {messageSuccessSendEmail &&
                                <p style={{
                                    fontSize: 15,
                                    color: 'green',
                                    fontWeight: 500,
                                }}>{messageSuccessSendEmail}</p>}
                        </div>
                        <div className="text text-center">
                            <button
                                className="btn btn-lg mt-1"
                                type="button"
                                style={{
                                    borderColor: "white",
                                    background: "linear-gradient(to right, #FCC546, #FDD681)",
                                    color: '#1E1E1E',
                                    fontFamily: "Poppins",
                                    fontSize: "14px",
                                    fontWeight: "600",
                                    borderRadius: '20px',
                                }}
                                onClick={() => navigate('/')}
                            >
                                Revenir
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {/* START MODAL */}
            {
                showModalReviewStars === true && (
                    <ReviewStarsModal
                        setShowModalReviewStars={setShowModalReviewStars}
                    />
                )
            }
            {/* END MODAL */}
            {/* START WHEEL MODAL */}
            {
                showModalWheel && (
                    <WheelModal
                        showModalWheel={showModalWheel}
                        setShowModalWheel={setShowModalWheel}
                        email={emailMiamus}
                    />
                )
            }
            {/* END WHEEL MODAL */}
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import Header from '../common/components/Header'
import { useSelector } from 'react-redux';
import './PaymentProcess.css';
import { countAllTotals, formatPrice, getAllProductsFromAllOrdersByTable } from './../common/helpers/utils';
import { getOrdersByTableService } from '../common/apiServices/orders/order.service';
import InsertCouponModal from './InsertCouponModal';
import PourboireModal from './PourboireModal';

export default function PaymentProcess() {
    const operationsInfos = useSelector((state) => state?.operation?.operationInfos)
    const { id_table } = useSelector((state) => state?.operation?.operationInfos?.tableInfos)


    // handle pourboire
    const [pourboire, setPourboire] = useState(0);
    const [showCustomPourboireValModal, setShowCustomPourboireValModal] = useState(false);

    useEffect(() => {
        // allow closing modal when click outside
        const handleOutsideClick = (e) => {
            if (showCustomPourboireValModal === true && e.target.classList.contains('modal')) {
                setShowCustomPourboireValModal(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [showCustomPourboireValModal]);

    const calculateSubTotal = () => {
        if (operationsInfos?.tableInfos && operationsInfos?.clientInfos) {
            const { tableInfos, clientInfos } = operationsInfos;
            if (tableInfos?.type_payment_table === "OWN") {
                return countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByTableList))
            } else {
                if (tableInfos?.type_shared_payment === "CHOICE") {
                    return countAllTotals(clientInfos?.selectedProdsToPay)
                } else {
                    return clientInfos?.splittedAmountToPay
                }
            }
        }
    }

    // show total to pay all products of all orders by table
    const [ordersDataByTableList, setOrdersDataByTableList] = useState([]);

    // add useEffect 2 to handle Total products by table api
    useEffect(() => {
        // call api get all products of all orders by table where not 'free'
        getOrdersByTableService(id_table)
            .then((orders) => {
                setOrdersDataByTableList(orders);
            })
            .catch((err) => console.log(err))
    }, [])

    // coupon process
    const [showModalInsertCoupon, setShowModalInsertCoupon] = useState(false);
    const [isCouponValid, setIsCouponValid] = useState(false);
    const [couponData, setCouponData] = useState(null);
    const [discount, setDiscount] = useState(0);

    const handleSelectCoupon = () => {
        setShowModalInsertCoupon(true)
    }

    const totalAMountByCouponReduction = () => {
        return formatPrice(calculateSubTotal() * (couponData?.amountPromotion / 100))
    }

    // pourboire state
    const [showPourboireModal, setShowPourboireModal] = useState(false);

    const calculatedTotalToPay = () => {
        return calculateSubTotal() + Number(pourboire) - (totalAMountByCouponReduction() > 0 && totalAMountByCouponReduction()) + calculateUserfees(calculateSubTotal())
    }

    const calculateUserfees = (amount) => {
        const result = amount * (20 / 100);
        if (amount <= 350) {
            return result > 5 ? 5 : result;
        } else {
            return result > 9.80 ? 9.80 : result;
        }
    }

    return (
        <div style={{ backgroundColor: '#f7f6fa' }}>
            <Header />
            {/* Start payment method section */}
            <div className='container-fluid p-3 bg-white'
                style={{
                    marginTop: "10px",
                    borderRadius: "5px",
                    boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px inset",
                }}
            >
                <p style={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "800",
                    lineHeight: "32px",
                    paddingLeft: "15px"

                }}>Moyen de paiement</p>
                <ul class="list-group">

                    <li class="list-group-item"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: "none"
                        }}
                    >
                        <label class="form-check-label" for="secondRadio"
                            style={{
                                fontSize: "22px",
                                fontWeight: "600"
                            }}
                        >Carte de crédit
                        </label>
                        <input class="form-check-input me-1" type="radio" checked name="paymentMethod" value="" id="secondRadio" />
                    </li>
                    <li class="list-group-item"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            border: "none"
                        }}
                    >
                        <div>
                            <img src="/images/pay-radio-icon.svg" alt="pay_img" width={50} height={30} /> /
                            <img src="/images/google-pay-icon.svg" alt="pay_img" width={50} height={30} style={{ marginLeft: 7 }} />
                        </div>
                        <input class="form-check-input me-1" type="radio" name="paymentMethod" value="" id="firstRadio" />
                    </li>
                </ul>
            </div>
            <div className='container-fluid p-3 bg-white'
                style={{
                    marginTop: "10px",
                    borderRadius: "5px",
                    boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px inset",
                }}
            >
                <div className=''>
                    <ul class="list-group list-group-flush">
                        <li class="list-item"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "14px",
                                margin: 0
                            }}
                        >
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                <b>Sous-total</b>
                            </p>
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                <b>{`${formatPrice(calculateSubTotal())} Dhs`}</b>
                            </p>
                        </li>
                        <li class="list-group-item"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "14px",
                                margin: 0
                            }}
                        >
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                Frais d'utilisation
                            </p>
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                <b>{`${formatPrice(calculateUserfees(calculateSubTotal()))} Dhs`}</b>
                            </p>
                        </li>
                        {
                            couponData && (
                                <li class="list-group-item"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        padding: "14px",
                                        margin: 0
                                    }}
                                >
                                    <p style={{
                                        fontSize: "15px",
                                        fontFamily: "Poppins",
                                        margin: 0
                                    }}
                                    >
                                        Réduction “{couponData?.name_coupon}”
                                    </p>
                                    <p style={{
                                        fontSize: "15px",
                                        fontFamily: "Poppins",
                                        margin: 0
                                    }}
                                    >
                                        {`- ${totalAMountByCouponReduction()} Dhs`}
                                    </p>
                                </li>
                            )
                        }
                        <li class="list-group-item"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "14px",
                                margin: 0
                            }}
                        >
                            <p style={{
                                fontSize: "18px",
                                fontWeight: "800",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >Total</p>
                            <p style={{
                                fontSize: "18px",
                                fontWeight: "800",
                                fontFamily: "Poppins",
                                margin: 0,
                                background: "linear-gradient(to right, #FCC546, #FDD681)",
                                WebkitBackgroundClip: 'text',
                                color: 'transparent',
                                display: 'inline-block',
                                WebkitTextFillColor: 'transparent', // For Safari
                            }}
                            >

                                {`${formatPrice(calculatedTotalToPay())} Dhs`}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
            {/* coupon part */}
            <div style={{ textAlign: 'right', fontSize: 14, fontWeight: 700, cursor: 'pointer', paddingRight: 25 }}>
                <p onClick={handleSelectCoupon}>
                    <u>Entrer un coupon</u>
                </p>
            </div>
            {/* START MODAL */}
            {
                showModalInsertCoupon && (
                    <InsertCouponModal
                        title="Entrer un coupon"
                        setShowModalInsertCoupon={setShowModalInsertCoupon}
                        setCouponData={setCouponData}
                        setIsCouponValid={setIsCouponValid}
                    />
                )
            }
            {/* END MODAL */}
            {/* END coupon part */}
            <div className='container-fluid p-4 text text-center mt-2'>
                <button
                    className="btn btn-lg"
                    style={{
                        paddingLeft: "90px",
                        paddingRight: "90px",
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        borderColor: "white",
                        background: "linear-gradient(to right, #FCC546, #FDD681)",
                        color: '#1E1E1E',
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "600",
                    }}
                    type="button"
                    // onClick={handleCreateOperationByRightOption}
                    onClick={() => setShowPourboireModal(true)}
                    disabled={(calculateSubTotal() + Number(pourboire)) === 0}
                >
                    Payer {` ${formatPrice(calculatedTotalToPay())} Dhs`}
                </button>
                {/* Open Pourboire Modal */}
                {
                    showPourboireModal && (
                        <PourboireModal
                            setShowPourboireModal={setShowPourboireModal}
                            calculatedTotal={calculatedTotalToPay}
                            setPourboire={setPourboire}
                            totalAMountByCouponReduction={totalAMountByCouponReduction}
                            calculateSubTotal={calculateSubTotal}
                            discount={couponData?.amountPromotion}
                            user_fees_amount={calculateUserfees(calculateSubTotal())}
                        />
                    )
                }
                {/* END Pourboire Modal */}
            </div>
        </div>
    )
}

import React, { useState } from 'react'
import './Register.style.css'
import { clientRegisterService } from '../../common/apiServices/auth/auth.service';

export default function RegisterModal({
    title,
    setshowModalRegisterClient,
    setshowModalLoginClient
}) {

    const [clientRegisterData, setClientRegisterData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        password: "",
        isAcceptReceiveEmails: "N"
    })
    const [msgSuccessRegister, setMsgSuccessRegister] = useState("")

    const handleChangeRegisterClient = (event) => {
        const { name, type, checked, value } = event.target;
        if (type === 'checkbox') {
            // For checkbox, we want to store "Y" when checked and "N" when unchecked
            setClientRegisterData({
                ...clientRegisterData,
                [name]: checked ? 'Y' : 'N'
            });
        } else {
            // For other fields, just update the value as usual
            setClientRegisterData({
                ...clientRegisterData,
                [name]: value
            });
        }
    }

    const handleSubmitRegisterClient = (event) => {
        event.preventDefault();
        console.log("clientRegisterData :", clientRegisterData)
        // call api service register
        clientRegisterService(clientRegisterData)
            .then((data) => {
                if (data && data.code === 1) {
                    setClientRegisterData({
                        firstName: "",
                        lastName: "",
                        phone: "",
                        email: "",
                        password: "",
                        isAcceptReceiveEmails: "N"
                    })
                    setMsgSuccessRegister("Votre compte a été créé avec succès !")

                    // Wait 3 seconds before redirecting to the login screen
                    setTimeout(() => {
                        setshowModalRegisterClient(false)
                        // open login Modal
                        setshowModalLoginClient(true)
                    }, 3000);
                }
            })
    }
    return (
        <div
            className="modal fade show"
            id="shareModal"
            tabindex="-1"
            aria-labelledby="shareModalLabel"
            aria-hidden="true"
            style={{
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <div class="modal-dialog"
                style={{
                    maxWidth: '80vw',
                    width: 'fit-content',
                    margin: "auto",
                }}
            >
                <div class="modal-content"
                    style={{ borderColor: "none", }}
                >
                    <div class="modal-header">
                        <h5
                            class="modal-title"
                            id="shareModalLabel"
                            style={{
                                fontFamily: "Poppins",
                                fontSize: 23,
                                fontWeight: 600
                            }}
                        >{title} <span className='text text-warning'>Tablati</span></h5>
                        <img
                            src="images/close-icon.svg"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt="close-modal-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => setshowModalRegisterClient(false)}
                        />
                    </div>
                    <div class="modal-body">
                        <div class="d-grid gap-2">
                            <form onSubmit={handleSubmitRegisterClient}>
                                <div className='mb-3'>
                                    <label htmlFor='firstNameId'><b>Prénom*</b></label>
                                    <input type='text' className='form-control' name="firstName" required id="firstNameId" value={clientRegisterData?.firstName}
                                        onChange={handleChangeRegisterClient}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor='lastNameId'><b>Nom*</b></label>
                                    <input type='text' className='form-control' name="lastName" required id="lastNameId" value={clientRegisterData?.lastName}
                                        onChange={handleChangeRegisterClient}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor='phoneId'><b>Téléphone</b></label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name="phone"
                                        id='phoneId'
                                        value={clientRegisterData?.phone}
                                        placeholder="+212612345678"
                                        pattern="^\+212[67][0-9]{8}$"  // Regex for Moroccan numbers
                                        required
                                        onChange={handleChangeRegisterClient}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor='emailId'><b>Adresse e-mail*</b></label>
                                    <input type='email' className='form-control focus-warning' name="email" required id="emailId" value={clientRegisterData?.email}
                                        onChange={handleChangeRegisterClient}
                                    />
                                </div>
                                <div className='mb-3'>
                                    <label htmlFor='passwordId'><b>Mot de passe*</b></label>
                                    <input type='password' className='form-control' name="password" required id="passwordId" value={clientRegisterData?.password}
                                        onChange={handleChangeRegisterClient}
                                    />
                                </div>
                                <div style={{
                                    display: 'flex', justifyContent: 'space-between'
                                }}>
                                    <p style={{
                                        fontSize: 12,
                                        fontWeight: 500,
                                        fontStyle: 'italic',
                                    }}>
                                        Je souhaite recevoir des e-mail de la part de Tablati
                                        avec les meilleurs promortions, les offres de fidélité
                                        et les évènements
                                    </p>
                                    <div class="form-check form-switch">
                                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
                                            name="isAcceptReceiveEmails" onChange={handleChangeRegisterClient} value={clientRegisterData?.isAcceptReceiveEmails} />
                                    </div>
                                </div>
                                {
                                    msgSuccessRegister && <p className='text text-success'>{msgSuccessRegister}</p>
                                }
                                <div class="d-flex justify-content-center">
                                    <button type="submit" class="btn btn-warning m-1 fw-semibold">Je m'inscris!</button>
                                    <button type="button" class="btn btn-outline-warning m-1 fw-semibold"
                                        onClick={() => setshowModalRegisterClient(false)}
                                    >Annuler</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

import { useEffect, useState } from "react";
import { getPackWithCategoriesAndProductsService } from "../../common/apiServices/packs/pack.service";

export default function PackDetailsModal({
    id_pack,
    code_ref_pack,
    setShowPackDetailsModal,
    handleCheckedPacksProp
}) {

    const [packCategoriesProductsData, setPackCategoriesProductsData] = useState({})
    const [checkedPacks, setCheckedPacks] = useState([])
    const [messagePackValidation, setMessagePackValidation] = useState("")

    useEffect(() => {
        // getPackById with categories & products
        getPackWithCategoriesAndProductsService(id_pack)
            .then((data) => setPackCategoriesProductsData(data.packWithCategoriesAndProducts))
            .catch((err) => console.log(err))
    }, [id_pack])

    const handleChangeCheckedPacks = (prodPack, idCategory) => {
        // Remove any previously checked product from the same category
        let updatedProdPack = checkedPacks.filter((item) => item.id_category !== idCategory);
        updatedProdPack = [...updatedProdPack, { ...prodPack }];

        setCheckedPacks(updatedProdPack);
        console.log("Updated Checked Packs:", updatedProdPack);
    }

    const handleValidationCheckedPacks = () => {
        if (checkedPacks.length === packCategoriesProductsData.categories.length) {
            // call function props and pass it validated checked packs
            // here set pack.id and pack_name
            handleCheckedPacksProp(checkedPacks, id_pack, packCategoriesProductsData?.name, code_ref_pack);
            setShowPackDetailsModal(false)
        } else {
            setMessagePackValidation("Veuillez sélectionner 1 article par section");
        }
    }

    return (
        <div>
            {/* START TEST MODA */}
            <div
                className="modal fade show"
                id="shareModal"
                tabindex="-1"
                aria-labelledby="shareModalLabel"
                aria-hidden="true"
                style={{
                    // this stye what make modal display
                    display: 'block',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
            >
                <div class="modal-dialog">
                    <div class="modal-content"
                        style={{ borderColor: "none", }}
                    >
                        <div class="modal-header">
                            <img
                                src="/images/icon-arrow-left.svg"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                alt="close-modal-icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => setShowPackDetailsModal(false)}
                            />
                            <h5
                                class="modal-title text tex-center"
                                id="shareModalLabel"
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "22px",
                                    fontWeight: "600",
                                }}
                            >{packCategoriesProductsData?.name}</h5>
                            <div></div>
                        </div>
                        <div class="modal-body">
                            <div className='container pack-details_section'>
                                <p className="text text-success">1 produit maximum par section !</p>
                                {
                                    packCategoriesProductsData?.categories?.map((item) => {
                                        return (
                                            <div className="pack_item_details mb-2">
                                                <h5 style={{ fontSize: 21, fontWeight: 600 }}>{item?.name}</h5>
                                                {/* loop on products here */}
                                                {
                                                    item?.products.length > 0 && item?.products?.map((prod) => {
                                                        return (
                                                            <div style={{
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                                padding: "7px"
                                                            }}>
                                                                <div>
                                                                    <h4 style={{ fontSize: 18, fontWeight: 400 }}>{prod?.productName}</h4>
                                                                </div>
                                                                <div class="form-check">
                                                                    <input
                                                                        class="form-check-input packChecks"
                                                                        type="radio"
                                                                        name={`checkedProdPackByCategoryID_${item?.id}`}
                                                                        id="flexRadioDefault1"
                                                                        onChange={() => handleChangeCheckedPacks(prod, item.id)}
                                                                        style={{
                                                                            border: 'solid #FF9500 2px'
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            {messagePackValidation &&
                                <div className="alert alert-danger" onClick={() => setMessagePackValidation("")}>
                                    {messagePackValidation}
                                </div>
                            }
                            <div style={{ marginTop: "25px", textAlign: 'center' }}>
                                <button
                                    style={{
                                        paddingLeft: "80px",
                                        paddingRight: "80px",
                                        paddingTop: '15px',
                                        paddingBottom: '15px',
                                        background: "linear-gradient(to right, #FCC546, #FDD681)",
                                        color: '#1E1E1E',
                                        border: "none"
                                    }}
                                    type="button"
                                    class="btn btn-primary btn-lg"
                                    onClick={handleValidationCheckedPacks}
                                >
                                    Terminer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* END TEST MODA */}

        </div>
    )
}

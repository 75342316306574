import React, { useEffect, useState } from 'react'
import { getSupplementsByProductService } from '../../common/apiServices/supplements/supplement.service';
import { getSuggestionsWithTheirCategoriesAndProducts } from '../../common/apiServices/suggestions/suggestion.service';
import '../../../src/common/styles/app.style.css'

export default function SupplementsProduct({
    // id_product,
    selectedProduct,
    handleCheckedSupplimentsProp
}) {

    const [suppementsByProductList, setSupplementsByProductList] = useState([]);
    const [checkedSuppements, setCheckedSupplements] = useState([]);
    const [suggestionsProduct, setSuggestionsProduct] = useState([]);

    const handleChangeCheckedSuppliments = (item) => {
        let copyCheckedSuppements;
        const foundSuppliment = checkedSuppements.find((supp) => supp.id_supplement === item.id_supplement)
        if (foundSuppliment) {
            copyCheckedSuppements = checkedSuppements.filter((supp) => supp.id_supplement !== item.id_supplement);
        } else {
            copyCheckedSuppements = [...checkedSuppements, item];
        }
        setCheckedSupplements(copyCheckedSuppements)
        handleCheckedSupplimentsProp(copyCheckedSuppements)
    }

    useEffect(() => {
        getSupplementsByProductService(selectedProduct?.id)
            .then((data) => setSupplementsByProductList(data.supplementsProduct))
            .catch((err) => console.log(err))

        // these api called just to handle "separation Line"
        getSuggestionsWithTheirCategoriesAndProducts(selectedProduct.id_category)
            .then((data) => {
                setSuggestionsProduct(data.suggestionsWithCategoriesAndProducts)
            })
            .catch((err) => console.log(err))

    }, [selectedProduct?.id])

    return (
        <div>
            <div className='supplements-section'>
                {suppementsByProductList && <h5 style={{ fontSize: 21, fontWeight: 600 }}>Suppléments</h5>}
                <div className='list-suppelemnts mt-3'>
                    {
                        suppementsByProductList && suppementsByProductList.map((item) => {
                            return (
                                <>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        // alignItems: "center"
                                    }}>
                                        <div>
                                            <h4 style={{ fontSize: 19, fontWeight: 500 }}>{item.name_supplement}</h4>
                                            <p style={{ fontSize: 14, fontWeight: 400 }}>+ {item.price_supplement} DH</p>
                                        </div>
                                        <div class="form-check">
                                            <input
                                                class="form-check-input suppChecks"
                                                type="checkbox"
                                                id="flexCheckChecked"
                                                // value={item}
                                                onChange={() => handleChangeCheckedSuppliments(item)}
                                                style={{
                                                    width: "19px",
                                                    height: "19px",
                                                    cursor: "pointer",
                                                    border: 'solid #FF9500 2px',
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
                {suppementsByProductList && (suggestionsProduct !== undefined) && <hr />}

            </div>
        </div>
    )
}

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import FoodMenu from "./components/FoodMenu";
import ProductsList from "./components/ProductsList";
import Product from "./components/Product";
import Cart from "./components/Cart";
import Payment from "./components/Payment";
import "./App.css";
import PaymentProcess from "./components/PaymentProcess";
import SelectedProductsToPay from "./components/SelectedProductsToPay";
import OrderInfo from "./components/OrderInfo";
import PaymentSuccess from "./components/PaymentSuccess";
import ReviewStars from "./components/ReviewStars";
import Receipt from "./components/Receipt";
import PaymentCMIFail from "./components/payment/PaymentCMIFail";
import UserAccountInfos from "./components/account/UserAccountInfos";
import Mymiamus from "./components/account/Mymiamus";
import PrivateRoute from "./common/helpers/authorization";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Router>
      {/* ToastContainer placed here so it is available across all components */}
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/food-menu" element={<FoodMenu />} />
        <Route path="/products" element={<ProductsList />} />
        <Route path="/products/:id" element={<Product />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/payment-process" element={<PaymentProcess />} />
        <Route
          path="/selected-products-topay"
          element={<SelectedProductsToPay />}
        />
        <Route path="/order-infos" element={<OrderInfo />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-failure" element={<PaymentCMIFail />} />
        <Route
          path="/review-stars/:isreview/:isopen"
          element={<ReviewStars />}
        />
        <Route path="/receipt" element={<Receipt />} />

        <Route
          path="/user-account-infos"
          element={
            <PrivateRoute>
              <UserAccountInfos />
            </PrivateRoute>
          }
        />
        <Route
          path="/user-miamus"
          element={
            <PrivateRoute>
              <Mymiamus />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;

import { useState } from "react";
import { isAuthenticated, RemoveItemLocalStorage } from "../../helpers/authentication";
import { clientLogoutService } from "../../apiServices/auth/auth.service";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

export default function GenericDropDown({
    setshowModalRegisterClient,
    setshowModalLoginClient
}) {
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleLogout = () => {
        clientLogoutService()
            .then(() => {
                RemoveItemLocalStorage("clientAuthInfos")
                setIsOpen(false)
                // show message with toastr & navigate to home
                toast.success("Déconnexion réussie !", {
                    position: 'bottom-right',
                    autoClose: 2000,
                });
                navigate('/')

            })
            .catch((err) => console.log(err))
    }

    return (
        <div
            className="custom-dropdown"
            style={{
                position: "relative", // Ensures the dropdown menu positions itself correctly
                left: 5
            }}
        >
            <div
                onClick={toggleDropdown}
                className="dropdown-trigger"
                style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                }}
            >
                <div style={{
                    borderRadius: "50%",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: "45px",
                    height: '45px',
                    background: "linear-gradient(to right, #FCC546, #FDD681)",
                }}
                >
                    <img
                        src="/images/avatar.png"
                        alt="User"
                    />
                </div>
                <img
                    src={isOpen ? "/images/up_arrow.svg" : "/images/down_arrow.svg"}
                    alt="Toggle Dropdown"
                />
            </div>
            {isOpen && (
                <ul
                    className="dropdown-menu"
                    style={{
                        position: "absolute",
                        top: "100%", // Positions it directly below the trigger
                        right: 0, // Aligns it to the right edge of the parent
                        left: 0,
                        background: "white",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        listStyle: "none",
                        padding: "8px 0",
                        margin: 0,
                        borderRadius: "4px",
                        zIndex: 1000,
                        display: isOpen ? "block" : "none",
                    }}
                >
                    {
                        !isAuthenticated() && (
                            <>
                                <li
                                    style={{
                                        padding: "10px 15px",
                                        cursor: "pointer",
                                        transition: "background 0.3s",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.target.style.background = "#f0f0f0")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.target.style.background = "transparent")
                                    }
                                    onClick={() => {
                                        setshowModalLoginClient(true);
                                        setIsOpen(false)
                                    }}
                                >
                                    Se connecter
                                </li>
                                <li
                                    style={{
                                        padding: "10px 15px",
                                        cursor: "pointer",
                                        transition: "background 0.3s",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.target.style.background = "#f0f0f0")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.target.style.background = "transparent")
                                    }
                                    onClick={() => setshowModalRegisterClient(true)}
                                >
                                    S'inscrire
                                </li>
                            </>
                        )
                    }

                    {
                        isAuthenticated() &&
                        (
                            <>
                                <li
                                    style={{
                                        padding: "10px 15px",
                                        cursor: "pointer",
                                        transition: "background 0.3s",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.target.style.background = "#f0f0f0")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.target.style.background = "transparent")
                                    }
                                    onClick={() => navigate('/user-account-infos')}
                                >
                                    Mon compte
                                </li>
                                <li
                                    style={{
                                        padding: "10px 15px",
                                        cursor: "pointer",
                                        transition: "background 0.3s",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.target.style.background = "#f0f0f0")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.target.style.background = "transparent")
                                    }
                                    onClick={() => navigate('/user-miamus')}
                                >
                                    Mes miamus
                                </li>
                                <li
                                    style={{
                                        padding: "10px 15px",
                                        cursor: "pointer",
                                        transition: "background 0.3s",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.target.style.background = "#f0f0f0")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.target.style.background = "transparent")
                                    }
                                >
                                    Mes méthodes de paiements
                                </li>
                                <li
                                    style={{
                                        padding: "10px 15px",
                                        cursor: "pointer",
                                        transition: "background 0.3s",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.target.style.background = "#f0f0f0")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.target.style.background = "transparent")
                                    }
                                >
                                    Mon historique
                                </li>
                                <li
                                    style={{
                                        padding: "10px 15px",
                                        cursor: "pointer",
                                        transition: "background 0.3s",
                                    }}
                                    onMouseEnter={(e) =>
                                        (e.target.style.background = "#f0f0f0")
                                    }
                                    onMouseLeave={(e) =>
                                        (e.target.style.background = "transparent")
                                    }
                                    onClick={handleLogout}
                                >
                                    Se déconnecter
                                </li>
                            </>
                        )
                    }
                </ul>
            )}
        </div>
    );
}

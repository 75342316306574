import React from 'react'
import { useNavigate } from 'react-router-dom'
import { API_URL } from '../../env';

export default function GenericListCategories({
    id,
    image,
    categoryName,
    idActiveMenu
}) {
    const navigate = useNavigate();
    return (
        <div
            lassName='shadow-lg p-3 mb-5 bg-body-tertiary rounded'
            style={{
                height: '135px',
                display: "flex",
                flexDirection: "column",
                alignItems: 'center',
                margin: '3px',
                borderRadius: '8px',
                cursor: 'pointer',
                flex: 1,
            }}
            onClick={() => navigate(`/products?category=${id}&id_menu=${idActiveMenu}`)}
        >
            {/* force here resizing */}
            <img src={`${API_URL}/images/${image}`} alt="category_image" width={102} height={86} />
            <h4 style={{
                fontFamily: "Poppins",
                fontSize: '15px',
                fontWeight: '500',
                textAlign: 'center',
                padding: '4px',
            }}>
                {categoryName}
            </h4>
        </div>
    )
}

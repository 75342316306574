import { useState } from 'react'
import './Register.style.css'
import { forgetPasswordEmailService } from '../../common/apiServices/auth/auth.service'

export default function ForgetPasswordModal({
    title,
    setShowModalForgetPassword,
    setshowModalLoginClient
}) {

    const [forgetPassEmail, setForgetPassEmail] = useState({
        email: "",
    })
    const [msgErrorForgetEmail, setMsgErrorForgetEmail] = useState("")
    const [msgErrorLoginEmailInfo, setMsgErrorLoginEmailInfo] = useState("")

    const handleChangeForgetPasswordEmail = (event) => {
        const { name, value } = event.target;
        // For other fields, just update the value as usual
        setForgetPassEmail({
            ...forgetPassEmail,
            [name]: value
        });
    }

    const handleSubmitForgetPasswordEmail = (event) => {
        event.preventDefault();
        console.log("forgetPassEmail :", forgetPassEmail)
        // call api service Login
        forgetPasswordEmailService(forgetPassEmail)
            .then((data) => {
                if (data && data.code === 'NOTFOUND') {
                    setMsgErrorForgetEmail("Email introuvable !")
                } else {
                    setForgetPassEmail({
                        email: "",
                    })
                    setMsgErrorLoginEmailInfo("Veuillez vérifier votre Email pour réinitialiser votre mot de pass !")
                    setMsgErrorForgetEmail("")
                }
            })
    }
    return (
        <div
            className="modal fade show"
            id="shareModal"
            tabindex="-1"
            aria-labelledby="shareModalLabel"
            aria-hidden="true"
            style={{
                // this stye what make modal display
                display: 'block',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <div class="modal-dialog"
                style={{
                    maxWidth: '80vw',
                    width: '400px',
                    margin: "auto",
                }}
            >
                <div class="modal-content"
                    style={{ borderColor: "none", }}
                >
                    <div class="modal-header">
                        <h5
                            class="modal-title"
                            id="shareModalLabel"
                            style={{
                                fontFamily: "Poppins",
                                fontSize: 23,
                                fontWeight: 600
                            }}
                        >{title}</h5>
                        <img
                            src="images/close-icon.svg"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            alt="close-modal-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setShowModalForgetPassword(false)
                                setshowModalLoginClient(true)
                            }}
                        />
                    </div>
                    <div class="modal-body">
                        <div class="d-grid gap-2">
                            <form onSubmit={handleSubmitForgetPasswordEmail}>
                                <div className='mb-2'>
                                    <label className='fw-semibold' htmlFor='emailId'>Adresse e-mail*</label>
                                    <input type='email' className='form-control focus-warning' name="email" required id="emailId" value={forgetPassEmail?.email}
                                        onChange={handleChangeForgetPasswordEmail}
                                    />
                                </div>
                                {
                                    msgErrorLoginEmailInfo && <p className='text text-success text-small'>{msgErrorLoginEmailInfo}</p>
                                }
                                {
                                    msgErrorForgetEmail && <p className='text text-danger text-small'>{msgErrorForgetEmail}</p>
                                }
                                <div class="d-flex justify-content-center">
                                    <button type="submit" class="btn btn-warning m-1 fw-semibold">
                                        Continuer
                                    </button>
                                    <button type="button" class="btn btn-outline-warning m-1 fw-semibold"
                                        onClick={() => setShowModalForgetPassword(false)}
                                    >Annuler</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

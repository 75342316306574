import React from 'react'
import { useSelector } from 'react-redux'
import { currentDateReceiptFormatter, formatPrice, generateReceiptId } from '../common/helpers/utils'
import { useNavigate } from 'react-router-dom'

export default function Receipt() {
    const receiptInfos = useSelector((state) => state?.operation?.operationInfos?.receiptInfos)

    const commonStyle = {
        fontSize: 13,
        fontWeight: 500,
        fontFamily: 'Poppins'
    }
    const navigate = useNavigate()
    return (
        <div>
            <div className='m-3'>
                <div className='d-flex justify-content-between'>
                    <img src='/images/reviews/receipt_logo.png' width={139} height={79} />
                    <i class="bi bi-x"
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/review-stars/yes/no')}
                    >
                    </i>
                </div>
                <div className='mt-1'>
                    <h3 style={{ ...commonStyle, fontWeight: 600 }}>TABLATI S.A.S.11</h3>
                    <h3 style={{ ...commonStyle, color: '#7C7C7C' }}>Boulevard Ghandi</h3>
                    <h3 style={{ ...commonStyle, color: '#7C7C7C' }}>RCS:4430442</h3>
                </div>
                <div>
                    <h3 style={{ ...commonStyle, fontWeight: 600 }}>Paul</h3>
                    <h3 style={commonStyle}>11 Boulevard Ghandi</h3>
                    <h3 style={commonStyle}>2000 Casablanca</h3>
                    <h3 style={commonStyle}>N°société: 13030440</h3>
                </div>
            </div>
            <hr style={{ marginLeft: 10, marginRight: 10, color: 'black', marginTop: 20 }} />
            <div>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "14px",
                            margin: 0,
                            border: "none" // Remove underline
                        }}
                    >
                        <p style={{
                            fontSize: "12px",
                            fontFamily: "Poppins",
                            margin: 0,
                            fontWeight: 400
                        }}
                        >
                            Addition: 3021edz23-32e
                        </p>
                        <p style={{
                            fontSize: "12px",
                            fontFamily: "Poppins",
                            margin: 0,
                            fontWeight: 400
                        }}
                        >
                            Id reçu: {generateReceiptId()}
                        </p>
                    </li>
                    <li class="list-group-item"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            // padding: "14px",
                            margin: 0,
                            border: "none" // Remove underline
                        }}
                    >
                        <p></p>
                        <p style={{
                            fontSize: "12px",
                            fontFamily: "Poppins",
                            margin: 0,
                            fontWeight: 400
                        }}
                        >
                            Date: {currentDateReceiptFormatter()}
                        </p>
                    </li>
                </ul>
            </div>
            <div className='totals-details-section mt-3'>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: 0,
                            border: "none" // Remove underline
                        }}
                    >
                        <p style={{
                            fontSize: "12px",
                            fontFamily: "Poppins",
                            margin: 0,
                            fontWeight: 400
                        }}
                        >
                            Sous- Total
                        </p>
                        <p style={{
                            fontSize: "12px",
                            fontFamily: "Poppins",
                            margin: 0,
                            fontWeight: 500
                        }}
                        >
                            {receiptInfos?.paidAmount + " Dhs"}
                        </p>
                    </li>
                    <li class="list-group-item"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: 0,
                            border: "none" // Remove underline
                        }}
                    >
                        <p style={{
                            fontSize: "12px",
                            fontFamily: "Poppins",
                            margin: 0,
                            fontWeight: 400
                        }}
                        >
                            Pourboire
                        </p>
                        <p style={{
                            fontSize: "12px",
                            fontFamily: "Poppins",
                            margin: 0,
                            fontWeight: 500
                        }}
                        >
                            {`${formatPrice(receiptInfos?.pourboire)}`}
                        </p>
                    </li>
                    <li class="list-group-item"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: 0,
                            border: "none" // Remove underline
                        }}
                    >
                        <p style={{
                            fontSize: "12px",
                            fontFamily: "Poppins",
                            margin: 0,
                            fontWeight: 400
                        }}
                        >
                            Montant total de l’addition
                        </p>
                        <p style={{
                            fontSize: "12px",
                            fontFamily: "Poppins",
                            margin: 0,
                            fontWeight: 600
                        }}
                        >
                            {`${formatPrice(Number(receiptInfos?.paidAmount) + Number(receiptInfos?.pourboire))} Dhs`}
                        </p>
                    </li>
                </ul>
            </div>
            {/* END RECEIPT TOTALS SECTION */}
            <div style={{
                marginTop: 25,
                border: 'solid black 1px',
                margin: 10,
                // height: "100px"
            }}>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: 0,
                            border: "none" // Remove underline
                        }}
                    >
                        <p style={{
                            fontSize: "13px",
                            fontFamily: "Poppins",
                            margin: 0,
                            fontWeight: 500
                        }}
                        >
                            Vous avez payé <b>{`${formatPrice(Number(receiptInfos?.paidAmount))} Dhs`} </b>
                        </p>
                        <img src='/images/reviews/receipt_logo.png' width={139} height={79} />
                    </li>
                </ul>
            </div>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import Header from '../common/components/Header'
import SubHeader from './../common/components/SubHeader';
import { lineStyle } from './MostPopularProducts';
import GenericListProducts from './../common/components/GenericListProducts';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { countAllTotals, formatPrice, generateUniqueOrderNumber } from '../common/helpers/utils';
import { v4 as uuidv4 } from 'uuid';
import { createOrderService } from '../common/apiServices/orders/order.service';
import { addToCart } from '../redux/cartSlice';
import { addOperationInfos } from '../redux/operationSlice';
import { isAuthenticated } from '../common/helpers/authentication';


export default function Cart() {
  const { products, count } = useSelector((state) => state.cart.cartItems)
  const id_table = useSelector((state) => state?.operation?.operationInfos?.tableInfos?.id_table)

  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userAuthInfos = isAuthenticated()
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (showModal === true && e.target.classList.contains('modal')) {
        setShowModal(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [showModal]);

  const handlePopupRedirection = (route) => {
    setShowModal(false);
    navigate(route);
  };

  const handleConfirmCartOrder = () => {
    console.log('userAuthInfos :', userAuthInfos)

    const bodyObj = {
      num_order: generateUniqueOrderNumber(),
      status_order: "INPROGRESS",
      client_unique_value: uuidv4(),
      id_table: id_table,
      // send customer infos usefull for huberise
      customerHubInfos: {
        first_name: userAuthInfos ? userAuthInfos?.user?.firstName : "",
        last_name: userAuthInfos ? userAuthInfos?.user?.lastName : "",
        email: userAuthInfos ? userAuthInfos?.user?.email : "",
        phone: userAuthInfos ? userAuthInfos?.user?.phone : ""
      },
      products_order: products.map((item) => {
        return {
          id_product: item.id,
          product_name: item.name, // This added usefull for 'HuberiseBody'
          price: item.price, // This added usefull for 'HuberiseBody'
          quantity: item.quantity,
          ref_code_product: item.ref_code_caisse,
          supplements_prod: item?.supplements ? item.supplements.map((suppItem) => ({
            id: suppItem.id_supplement,
            name: suppItem.name_supplement,
            price: suppItem.price_supplement,
            code_ref_supp: suppItem.code_ref_supp
          })) : [],
          suggestions_prod: item?.suggestions ? item.suggestions.map((suggestItem) => ({
            id_prod: suggestItem.idProduct,
            name: suggestItem.productName,
            price: suggestItem.productPrice,
            ref_code_sugg_prod: suggestItem.productRefCode
          })) : [],
          pack: {
            id_pack: item?.id_pack || null, // This added usefull for 'HuberiseBody' Deals
            namePack: item?.name_pack || null,
            code_ref_pack: item?.code_ref_pack || null,
            products_pack: item?.packs ? item.packs.map((itemProdPack) => ({
              id: itemProdPack.idProduct,
              name: itemProdPack.productName,
              price: itemProdPack.productPrice,
              ref_code_pack_prod: itemProdPack.ref_code_caisse
            })) : []
          }
        }
      })
    }

    console.log("bodyObj :", bodyObj)
    // call api create clinet & use insertedId client in create order
    createOrderService(bodyObj)
      .then((data) => {
        if (data.code && data.code === 1) {
          console.log(data);

          // clear cart items from locaStorage synchronizing redux store (closed by order)
          let cartItemsStorage = JSON.parse(localStorage.getItem('cart'));
          cartItemsStorage = {
            ...cartItemsStorage,
            products: [],
            count: 0
          }
          localStorage.setItem('cart', JSON.stringify(cartItemsStorage));
          dispatch(addToCart(cartItemsStorage));

          // store from response the client_unique_value in localStorage and redux
          let storageOperationsData = JSON.parse(localStorage.getItem('operationInfos')) || {};
          storageOperationsData = {
            ...storageOperationsData,
            clientInfos: {
              ...storageOperationsData.clientInfos,
              id_client: data?.client_session_infos?.id_client,
              client_session_id: data?.client_session_infos?.client_unique_value
            },
            // tableInfos: {
            //   ...storageOperationsData.tableInfos,
            //   OID: data?.orderIdForOID_PaymentValue
            // }
          };

          localStorage.setItem('operationInfos', JSON.stringify(storageOperationsData));
          dispatch(addOperationInfos(storageOperationsData))

          // close modal and navigate to home
          setShowModal(false);
          navigate('/order-infos');
        }
      })
      .catch((err) => console.log(err))
  }

  return (
    <div style={{ backgroundColor: '#f7f6fa' }}>
      <Header />
      <SubHeader redirectTo="/food-menu" />
      <div className='container-fluid bg-white'>
        <div>
          <h3 style={{
            fontFamily: "Poppins",
            fontSize: '22px',
            paddingLeft: "6px"
          }}>
            Mon Panier
          </h3>
        </div>
        <div style={lineStyle}>
        </div>
        {
          products && products.length > 0 ?
            products.map(item => {
              return (
                <>
                  <GenericListProducts
                    product={item}
                    isCartPage={true}
                  />
                </>
              )
            }) :
            "Aucun produit trouvé !"
        }
        {
          products && products.length > 0 && (
            <>
              <div
                className="mt-5 mb-5 p-3 shadow-lg bg-body-tertiary rounded"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: "20px",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                  marginLeft: "8px",
                  marginRight: "8px",
                }}
              >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <p style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: 500, marginBottom: 0 }}>Nombre d'articles:</p>
                  <p style={{ fontFamily: "Poppins", fontSize: "18px", marginBottom: 0 }}>{count}</p>
                </div>
                <hr />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <p style={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: 500, marginBottom: 0 }}>Montant total:</p>
                  <p style={{ fontFamily: "Poppins", fontSize: "18px", marginBottom: 0 }}>
                    {`${formatPrice(countAllTotals(products))} Dhs`}
                  </p>
                </div>
              </div>

              {/* END PART 1 */}
              <div style={{ marginTop: "20px", marginBottom: "50px", textAlign: 'center' }}>
                <button
                  style={{
                    paddingLeft: "130px",
                    paddingRight: "130px",
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    borderColor: "white",
                    background: "linear-gradient(to right, #FCC546, #FDD681)",
                    color: '#1E1E1E',
                    fontSize: 18,
                    fontWeight: 500,
                    fontFamily: 'Poppins',
                  }}
                  type="button"
                  className="btn btn-primary btn-lg"
                  onClick={() => setShowModal(true)}
                >
                  Confirmer
                </button>
                {
                  showModal && (
                    <div className="modal fade show" id="confirmationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
                      style={{
                        display: 'block',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <div className="modal-dialog modal-dialog-centered"
                        style={{
                          maxWidth: '80vw',
                          width: 'fit-content',
                          margin: "auto"
                        }}
                      >
                        <div className="modal-content">
                          <div className="modal-body"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              paddingLeft: "35px",
                              paddingRight: "35px",
                            }}
                          >
                            <img src="/images/Confirmation.svg" alt="" />
                            <div style={{
                              width: "244px",
                              height: "66px",
                              textAlign: "center"
                            }}>
                              <h1 style={{
                                fontSize: '20px',
                                fontWeight: '400',
                                fontFamily: "Poppins",
                              }}>
                                Voulez-vous confirmer votre commande ?
                              </h1>
                            </div>
                            <div
                              style={{
                                width: "270px",
                                height: "70px",
                                background: "linear-gradient(to right, #FCC546, #FDD681)",
                                borderColor: "white",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                              className="btn btn-lg"
                              onClick={handleConfirmCartOrder}
                            >
                              <p style={{ marginBottom: "8px", color: '#1E1E1E' }}>
                                Oui, je confirme
                              </p>
                            </div>
                            <div className='bg-light'
                              style={{
                                width: "270px",
                                height: "72px",
                                borderRadius: '10px',
                                marginTop: '20px',
                                padding: "12px",
                                border: "solid black 1px",
                                marginBottom: "20px",
                                cursor: "pointer"
                              }}
                              onClick={() => handlePopupRedirection("/food-menu")}
                            >
                              <p style={{
                                fontFamily: "Poppins",
                                fontSize: '16px',
                                color: 'black',
                              }}
                              >
                                Pas encore, je souhaite ajouter des produits
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            </>
          )
        }
        {/* div end model above */}
      </div>
    </div>
  )
}

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function SubHeader({
  subHeaderTitle
}) {
  const { num_table } = useSelector((state) => state?.operation?.operationInfos?.tableInfos)
  const navigate = useNavigate()

  const location = useLocation();
  const handleBackNavigation = () => {
    console.log('location :', location)
    navigate(-1)
  }

  useEffect(() => {
    console.log("Route changed:", location.pathname);
  }, [location]);

  return (
    <div className="container-fluid bg-white"
      style={{
        height: "80px",
        marginTop: "10px",
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'sticky',
        top: '80px',
        // top should have the same value of Header height
        zIndex: 100,
      }}
    >
      <div>
        <img
          src="/images/icon-arrow-left.svg"
          alt="icon-arrow-left"
          style={{ cursor: 'pointer' }}
          onClick={handleBackNavigation}
        />
      </div>
      {
        subHeaderTitle ?
          <h3 style={{
            fontSize: '25px',
            textAlign: "center",
            fontWeight: 700,
            textDecoration: "underline"
          }}
          > {subHeaderTitle}
          </h3>
          :
          <>
            <h3 style={{
              fontSize: '23px',
              textAlign: "center",
            }}
            >
              {`Table #${num_table} `}
              <img src="/images/quotes.svg" style={{ marginBottom: "35px" }} alt="" />
            </h3>
          </>
      }

      <div>
        {/* empty div */}
      </div>
    </div>
  )
}

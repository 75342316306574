import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { countAllTotals, formatPrice, getAllProductsFromAllOrdersByTable, showTotalPlusProductSuppsAndSuggsAndPacks } from './../common/helpers/utils';
import Header from '../common/components/Header';
import { addSelectedProductsToPayByChoiceService, getOrdersByTableService } from '../common/apiServices/orders/order.service';
import { addOperationInfos } from '../redux/operationSlice';

export default function SelectedProductsToPay() {

    // const id_client = getClientUserInfosFromLocalStorage()?.id_client;
    const id_table = useSelector((state) => state?.operation?.operationInfos?.tableInfos?.id_table)
    const { id_client } = useSelector((state) => state?.operation?.operationInfos?.clientInfos)
    const selectedProdsToPay = useSelector((state) => state?.operation?.operationInfos?.clientInfos?.selectedProdsToPay)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    // handle calculs logic
    const [ordersDataByActiveTableForSharedChoice, setOrdersDataByActiveTableForSharedChoice] = useState([])
    const [toRefreshCartProductsToPay, setToRefreshCartProductsToPay] = useState(false);

    // checkBox State Logic
    const [checkedProductsToPay, setCheckedProductsToPay] = useState([])
    const [requiredMessageSelectProdToPay, setRequiredMessageSelectProdToPay] = useState("")
    const [foundConflictMessageSelectProdToPay, setFoundConflictMessageSelectProdToPay] = useState("")


    const handleChangeCheckedProductsToPay = (checkedItem, order) => {
        const { id, id_client_ordering } = checkedItem;

        // Check if the item already exists in the state
        const isExist = checkedProductsToPay.find((item) => item.id === id && item.id_client_ordering === id_client_ordering);

        if (!isExist) {
            // Adding item to checked products.
            setCheckedProductsToPay((prev) => {
                const newItems = [...prev, { ...checkedItem, order_id: order?.id_order }];
                return newItems;
            });
        } else {
            // Removing item from checked products.
            setCheckedProductsToPay((prev) => {
                const filteredItems = prev.filter((item) => !(item.id === id && item.id_client_ordering === id_client_ordering));
                console.log('Filtered Checked Products:', filteredItems);
                return filteredItems;
            });
        }
    };


    const handleConfirmSelectedProductsToPay = () => {
        // store CheckedProductsToPay in locastirage Async with redux store
        if (checkedProductsToPay && checkedProductsToPay.length > 0) {
            const transformedBodyData = {
                id_client_selecting: id_client,
                ids_checked_products: checkedProductsToPay.map(product => ({
                    id: product.product_id,
                    order_id: product.order_id
                }))
            };

            // call api add selected product to pay
            addSelectedProductsToPayByChoiceService(transformedBodyData)
                .then((data) => {
                    if (data?.code === 0) {
                        setFoundConflictMessageSelectProdToPay("Produit(s) trouvé déja séléctionné, ")
                    }
                    if (data?.code === 1) {
                        // store CheckedProductsToPay in locastirage Async with redux store
                        let operationInfosStorage = JSON.parse(localStorage.getItem('operationInfos')) || {};
                        if (operationInfosStorage) {
                            const clientInfosStorage = operationInfosStorage?.clientInfos || {};

                            // Ensure selectedProdsToPay is always an array to make sure add even only one checked item
                            clientInfosStorage.selectedProdsToPay = clientInfosStorage.selectedProdsToPay || [];

                            // Update clientInfosStorage with the new selectedProdsToPay
                            operationInfosStorage = {
                                ...operationInfosStorage,
                                clientInfos: {
                                    ...operationInfosStorage.clientInfos,
                                    selectedProdsToPay: [
                                        ...clientInfosStorage.selectedProdsToPay, // Keep existing items
                                        ...checkedProductsToPay // Add new checked items
                                    ]
                                }
                            };

                            // Save the updated clientInfos back to localStorage
                            localStorage.setItem('operationInfos', JSON.stringify(operationInfosStorage));
                        }
                        // dispatch action to synch redux store
                        dispatch(addOperationInfos(operationInfosStorage))

                        // redirect to paymentProcess with pourboire & send payment
                        navigate('/payment-process');
                    }
                })
                .catch((err) => console.log(err))
        }
        else if (selectedProdsToPay && selectedProdsToPay.length > 0) {
            // redirect to paymentProcess with pourboire
            navigate('/payment-process');
        }
        else {
            setRequiredMessageSelectProdToPay("Aucun produit sélectionné à payer !");
        }
    }

    // main useEffect of compoenent
    useEffect(() => {
        // call api to get all order by table which is ON.
        getOrdersByTableService(id_table)
            .then((ordersData) => setOrdersDataByActiveTableForSharedChoice(ordersData))
            .catch(err => console.log(err))
    }, [toRefreshCartProductsToPay])


    return (
        <div style={{ backgroundColor: '#f7f6fa' }}>
            <Header redirectTo="/payment" />
            <div className='img-payment bg-white'
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    paddingTop: "10px"
                }}
            >
                <h3 className='text text-center'
                    style={{
                        fontSize: "19px",
                        fontWeight: "800px",
                        fontFamily: "Poppins",
                        paddingTop: "15px"
                    }}
                >
                    Choisir les articles à payer
                    <span
                        style={{ cursor: 'pointer', paddingLeft: '6px' }}
                        onClick={() => setToRefreshCartProductsToPay(true)}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z" />
                            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466" />
                        </svg>
                    </span>
                </h3>
            </div>
            <div className='container-fluid p-3 bg-white'>
                <div>
                    <ul class="list-group">
                        {
                            ordersDataByActiveTableForSharedChoice && ordersDataByActiveTableForSharedChoice.map((order) => {
                                return (
                                    <>
                                        {order?.products && order.products.map((item) => {
                                            return (
                                                <>
                                                    <li class="list-group-item" key={item.id}
                                                        style={{

                                                            border: "1px solid #00000036",
                                                            borderRadius: "20px",
                                                            margin: "5px",
                                                            padding: "16px",
                                                            cursor: "pointer",
                                                            background: item?.id_client_ordering === id_client && '#ffedbd',
                                                        }}
                                                    >
                                                        <div style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: 'center',
                                                        }}>
                                                            <div class="form-check">
                                                                <input
                                                                    class="form-check-input"
                                                                    type="checkbox"
                                                                    // value={item?.id}
                                                                    id="flexCheckChecked"
                                                                    // checked
                                                                    style={{
                                                                        width: "20px",
                                                                        height: "20px",
                                                                        cursor: "pointer",
                                                                        border: "solid gray 1.5px"
                                                                    }}
                                                                    onChange={() => handleChangeCheckedProductsToPay(item, order)}
                                                                    disabled={item?.isSelectedToPay === 'Y'}
                                                                />
                                                                <label style={{
                                                                    fontSize: "15px",
                                                                    fontFamily: "Poppins",
                                                                    marginLeft: '5px'
                                                                }}
                                                                >{item.name}</label>
                                                                {item?.isSelectedToPay === 'Y' && <span
                                                                    // className='bg-warning text-white'
                                                                    style={{
                                                                        background: 'lightblue',
                                                                        padding: '4px',
                                                                        paddingLeft: '8px',
                                                                        paddingRight: '8px',
                                                                        borderRadius: '15px',
                                                                        textAlign: 'center',
                                                                        marginLeft: '11px',
                                                                        fontWeight: 500
                                                                    }}> à payer</span>
                                                                }
                                                            </div>
                                                            <div style={{
                                                                display: 'flex',
                                                                flexDirection: 'column'
                                                            }}>
                                                                <p style={{
                                                                    fontSize: "15px",
                                                                    fontFamily: "Poppins",
                                                                    margin: 0
                                                                }}
                                                                >
                                                                    {`${formatPrice(item.price * item.quantity)} Dhs `}
                                                                </p>
                                                                {/* <div>
                                                                    {showTotalPlusProductSuppsAndSuggsAndPacks(item) > 0
                                                                        ?
                                                                        <span
                                                                            class="badge text-bg-warning"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#collapse-${item.id}`}
                                                                            aria-expanded="false"
                                                                            aria-controls={`collapse-${item.id}`}
                                                                            style={{ cursor: 'pointer' }}
                                                                            onClick={(e) => e.stopPropagation()} // Prevent event propagation
                                                                        >
                                                                            {`+${showTotalPlusProductSuppsAndSuggsAndPacks(item)}`}
                                                                            <i class="bi bi-chevron-down" style={{ marginLeft: 5 }}></i>
                                                                        </span>
                                                                        : null}
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        {/* Expandable Plus Product Details */}
                                                        {/* <div class="collapse" id={`collapse-${item.id}`} >
                                                            <ul style={{ paddingLeft: 20 }}>
                                                                {item.supplements && item.supplements.length > 0 && (
                                                                    <li>
                                                                        <strong style={{ color: "#7C7C7C" }}>Supplément :</strong> <span style={{ color: "#7C7C7C" }}>{item.supplements.map(s => s.name_supplement).join(" + ")}</span>
                                                                        <span style={{ color: "#7C7C7C" }}> ({item.supplements.reduce((sum, s) => sum + s.price_supplement, 0).toFixed(2)} Dhs)</span>
                                                                    </li>
                                                                )}

                                                                {item.suggestions && item.suggestions.length > 0 && (
                                                                    <li>
                                                                        <strong style={{ color: "#7C7C7C" }}>Suggestion :</strong> <span style={{ color: "#7C7C7C" }}>{item.suggestions.map(s => s.name_suggested_product).join(" + ")}</span>
                                                                        <span style={{ color: "#7C7C7C" }}> ({item.suggestions.reduce((sum, s) => sum + s.productPrice, 0).toFixed(2)} Dhs)</span>
                                                                    </li>
                                                                )}

                                                                {item.packs && item.packs.length > 0 && (
                                                                    <li>
                                                                        <strong style={{ color: "#7C7C7C" }}>{item.name_pack} :</strong> <span style={{ color: "#7C7C7C" }}> {item.packs.map(p => p.productName).join(" + ")}</span>
                                                                        <span style={{ color: "#7C7C7C" }}> ({item.packs.reduce((sum, p) => sum + p.productPrice, 0).toFixed(2)} Dhs)</span>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </div> */}
                                                    </li>
                                                </>
                                            )
                                        })}
                                    </>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            {/* start section 2 */}

            <div className='container-fluid p-3 bg-white'
                style={{
                    marginTop: "10px",
                    borderRadius: "5px",
                    boxShadow: "rgba(100, 100, 111, 0.1) 0px 7px 29px 0px inset",
                }}
            >
                <div>
                    <ul class="list-group list-group-flush">
                        <li class="list-item"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "14px",
                                margin: 0
                            }}
                        >
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                Montant total de l'addition
                            </p>
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                <b>{`${formatPrice(countAllTotals(getAllProductsFromAllOrdersByTable(ordersDataByActiveTableForSharedChoice)))} Dhs`}</b>
                            </p>
                        </li>
                        <li class="list-item"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "14px",
                                margin: 0
                            }}
                        >
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                Votre montant
                            </p>
                            <p style={{
                                fontSize: "15px",
                                fontFamily: "Poppins",
                                margin: 0
                            }}
                            >
                                <b>
                                    {
                                        (selectedProdsToPay || checkedProductsToPay) &&
                                        `${formatPrice(countAllTotals([
                                            ...(selectedProdsToPay || []), // Use existing selected products or an empty array
                                            ...(checkedProductsToPay || []) // Use checked products or an empty array
                                        ]))} Dhs`
                                    }
                                </b>
                            </p>
                        </li>
                    </ul>
                    <div className='requiredMessageSelectProdToPay'>
                        {
                            requiredMessageSelectProdToPay &&
                            <div className='alert alert-danger'
                                onClick={() => setRequiredMessageSelectProdToPay('')}
                            >
                                {requiredMessageSelectProdToPay}
                            </div>
                        }
                        <div className='foundConflictMessageSelectProdToPay'
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            {
                                foundConflictMessageSelectProdToPay &&
                                <div className='alert alert-danger' style={{
                                    width: '650px',
                                    textAlign: 'center'
                                }}>
                                    {foundConflictMessageSelectProdToPay}
                                    <u
                                        onClick={() => {
                                            setFoundConflictMessageSelectProdToPay('');
                                            setCheckedProductsToPay([]);
                                            setToRefreshCartProductsToPay(true);
                                            window.location.reload();
                                        }
                                        }
                                        style={{ cursor: 'pointer', fontWeight: 500 }}
                                    >
                                        {' '} Actualisez ici !
                                    </u>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <button
                            className="btn btn-lg mt-1"
                            type="button"
                            style={{
                                borderColor: "white",
                                background: "linear-gradient(to right, #FCC546, #FDD681)",
                                color: '#1E1E1E',
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "400",
                                borderRadius: '20px',
                                paddingLeft: '33px',
                                paddingRight: '33px'
                            }}
                            onClick={handleConfirmSelectedProductsToPay}
                        >
                            Confirmer
                        </button>
                    </div>
                </div>
            </div>
            {/* END BOTTOM SECTION */}
        </div>
    )
}
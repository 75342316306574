import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addToCart, decrementQnt, incrementQnt, removeFromCart } from '../../redux/cartSlice';
import { API_URL } from '../../env';
import { showTotalPlusProductSuppsAndSuggsAndPacks } from '../helpers/utils';

export default function GenericListProducts({ product, isCartPage }) {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [active, setActive] = useState(false);

    const addProductToCart = (prod, quant) => {
        const product = { ...prod, quantity: quant };
        let storageCart = JSON.parse(localStorage.getItem('cart')) || { products: [], count: 0 };

        // Ensure storageCart.products is an array
        storageCart.products = storageCart.products || [];

        let foundProduct = storageCart.products.find((item) => item.id === product.id);

        if (foundProduct) {
            // If the product is found, update its quantity
            foundProduct.quantity += quant;
            storageCart = { ...storageCart, count: storageCart.count + quant };
        } else {
            // If the product is not found, add it to the cart
            storageCart = {
                ...storageCart,
                products: [...storageCart.products, product],
                count: storageCart.count + quant,
            };
        }

        // Save the updated cart state to localStorage
        localStorage.setItem('cart', JSON.stringify(storageCart));
        dispatch(addToCart(storageCart));

        setActive(true);

        // Reset the effect after 1 second
        setTimeout(() => setActive(false), 500);
    };

    const decrementQuantity = (prod, quant) => {
        let storageCart = JSON.parse(localStorage.getItem('cart'));
        let foundProduct = storageCart.products.find((item) => item.id === prod.id);
        if (foundProduct) {
            if (foundProduct.quantity > 0 && storageCart.count > 0) {
                foundProduct.quantity = foundProduct.quantity - quant;
                storageCart = { ...storageCart, count: storageCart.count - quant };
                if (foundProduct.quantity === 0) {
                    // delete from localstorage and dispatch remove from cart
                    let filtredArrRemoveProduct = storageCart.products.filter((item) => item.id !== foundProduct.id)
                    storageCart = { ...storageCart, products: filtredArrRemoveProduct }
                    localStorage.setItem('cart', JSON.stringify({ ...storageCart }));
                    dispatch(removeFromCart(storageCart))
                }
            }
            // setItem again after change in localStorage 
            localStorage.setItem('cart', JSON.stringify({ ...storageCart }));
            dispatch(decrementQnt(storageCart))
        }
    }

    const incrementQuantity = (prod, quant) => {
        let storageCart = JSON.parse(localStorage.getItem('cart'));
        let foundProduct = storageCart.products.find((item) => item.id === prod.id);
        if (foundProduct) {
            foundProduct.quantity = foundProduct.quantity + quant;
            storageCart = { ...storageCart, count: storageCart.count + quant };
            // setItem again after change in localStorage 
            localStorage.setItem('cart', JSON.stringify({ ...storageCart }));
            dispatch(incrementQnt(storageCart))
        }
    }

    return (
        <div>
            <div className="mt-4 mb-3 p-3 shadow-lg bg-body-tertiary rounded"
                style={{
                    cursor: 'pointer',
                    pointerEvents: product.inStock === "N" && 'none',
                    opacity: product.inStock === "N" && '0.8',
                }}
                onClick={() => navigate(`/products/${product.id}`)}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}>
                    <div className="text">
                        <img
                            src={`${API_URL}/images/${product.image}`}
                            alt="product_img"
                            width="138"
                            height="134"
                        />
                    </div>
                    <div className="" style={{ width: "100%", padding: 0 }}>
                        <div className="" style={{ paddingLeft: "10px", display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <h5 className="prod-name"
                                    style={{
                                        fontFamily: "Poppins",
                                        fontSize: '15px',
                                    }}
                                >
                                    {product.name}
                                </h5>
                            </div>
                            <p className="prod-description"
                                style={{
                                    fontFamily: "Poppins",
                                    fontSize: "11px",
                                    fontWeight: "400px"
                                }}
                            >
                                {product.description}
                            </p>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    cursor: 'pointer'
                                }}
                                onClick={() => navigate(`/products/${product.id}`)}
                            >
                                <p className="text">
                                    <strong>{product.price} Dhs</strong>
                                    {isCartPage &&
                                        <span class="badge text-bg-warning" style={{ marginLeft: '7px' }}>
                                            {(showTotalPlusProductSuppsAndSuggsAndPacks(product) > 0
                                                ? (
                                                    <span
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#collapse-${product.id}`}
                                                        aria-expanded="false"
                                                        aria-controls={`collapse-${product.id}`}
                                                        style={{ marginLeft: "10px", cursor: 'pointer' }}
                                                        onClick={(e) => e.stopPropagation()} // Prevent event propagation
                                                    >
                                                        {`+${showTotalPlusProductSuppsAndSuggsAndPacks(product)}`} <i class="bi bi-chevron-down"></i>
                                                    </span>
                                                )
                                                : null)

                                            }

                                        </span>
                                    }
                                </p>
                                {
                                    isCartPage ? (
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center"
                                        }}
                                        >
                                            <div
                                                style={{ marginLeft: '5px', marginRight: '5px' }}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    decrementQuantity(product, 1);
                                                }}
                                            >
                                                <img src="/images/icon_decrement.svg" width={30} height={30} />
                                            </div>
                                            <div style={{ marginLeft: '5px', marginRight: '5px' }}>
                                                <h5>{product.quantity}</h5>
                                            </div>
                                            <div
                                                style={{ marginLeft: '5px', marginRight: '5px' }}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    incrementQuantity(product, 1);
                                                }}
                                            >
                                                <img src="/images/icon_increment.svg" width={29} height={29} />
                                            </div>
                                        </div>
                                    )
                                        :
                                        (
                                            <div
                                                // style = {{marginLeft:'5px', marginRight:'5px'}}
                                                style={{
                                                    marginLeft: '5px',
                                                    marginRight: '5px',
                                                    display: 'inline-block',
                                                    padding: '10px', // Adjust padding for better effect
                                                    borderRadius: '50%',
                                                    backgroundColor: active ? '#FFC300' : 'transparent', // Yellow background on click
                                                    transition: 'all 0.1s ease-in',
                                                    transform: active ? 'scale(1)' : 'scale(1)', // Enlarges the icon
                                                }}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    addProductToCart(product, 1);
                                                }}
                                            >
                                                {product.inStock === "Y"
                                                    ? <img src="/images/icon_add.svg" height={30} width={30} />
                                                    : <p>hors stock <i class="bi bi-dash-circle " style={{ fontSize: 17 }} ></i></p>}
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* Expandable Plus Product Details */}
                <div class="collapse" id={`collapse-${product.id}`} >
                    <ul style={{ paddingLeft: 20, paddingTop: 5 }}>
                        {product.supplements && product.supplements.length > 0 && (
                            <li>
                                <strong style={{ color: "#7C7C7C" }}>Supplément :</strong> <span style={{ color: "#7C7C7C" }}>{product.supplements.map(s => s.name_supplement).join(" + ")}</span>
                                <span style={{ color: "#7C7C7C" }}> ({product.supplements.reduce((sum, s) => sum + s.price_supplement, 0).toFixed(2)} Dhs)</span>
                            </li>
                        )}

                        {product.suggestions && product.suggestions.length > 0 && (
                            <li>
                                <strong style={{ color: "#7C7C7C" }}>Suggestion :</strong> <span style={{ color: "#7C7C7C" }}>{product.suggestions.map(s => s.productName).join(" + ")}</span>
                                <span style={{ color: "#7C7C7C" }}> ({product.suggestions.reduce((sum, s) => sum + s.productPrice, 0).toFixed(2)} Dhs)</span>
                            </li>
                        )}

                        {product.packs && product.packs.length > 0 && (
                            <li>
                                <strong style={{ color: "#7C7C7C" }}>{product.name_pack} :</strong> <span style={{ color: "#7C7C7C" }}> {product.packs.map(p => p.productName).join(" + ")}</span>
                                <span style={{ color: "#7C7C7C" }}> ({product.packs.reduce((sum, p) => sum + p.productPrice, 0).toFixed(2)} Dhs)</span>
                            </li>
                        )}
                    </ul>
                </div>
            </div>

        </div>
    )
}
